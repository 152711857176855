const {PDFDocument, rgb} = require('pdf-lib');

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    })
}

export const pdfEdit = async (existingPdfBytes, meta) => {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Seleziona la prima pagina del documento
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const margin_x = 5;
    const h = 20;

    const w = firstPage.getWidth() - margin_x*2;
    const margin_y = firstPage.getHeight() - h - margin_x;


    // Aggiungi il banner in sovrimpressione sulla prima pagina
    firstPage.drawRectangle({
        x: margin_x,
        y: margin_y,
        width: w,
        height: h,
        borderWidth: 1,
        borderColor: rgb(1, 0, 0),
        color: rgb(1, 1, 1),
    });
    const bannerText = JSON.stringify(meta);
    // const bannerTextWidth = 6;
    // const bannerTextHeight = bannerHeight / 2 + 5;
    const size = 10
    firstPage.drawText(bannerText, {
        x: margin_x + 30,
        y: margin_y + (h/2) - (size/2) + 2,
        size: size,
        color: rgb(0, 0, 0),
    });

    // Salva il documento PDF modificato
    return pdfDoc.save();
}