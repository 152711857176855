import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

export const NavBar = () => {

    return <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="#home">DENDe by Polito per UniTO - Cantiere Grugliasco</Navbar.Brand>
            {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
            {/*<Navbar.Collapse id="basic-navbar-nav">*/}
            {/*    <Nav className="me-auto">*/}
            {/*        <Nav.Link href="#home">Home</Nav.Link>*/}
            {/*        <Nav.Link href="#link">Link</Nav.Link>*/}
            {/*    </Nav>*/}
            {/*</Navbar.Collapse>*/}
        </Container>
    </Navbar>
}