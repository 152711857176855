import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Col, Container, Nav, Row} from "react-bootstrap";
import {NavBar} from "./components/nav-bar";
import {MultiPdfNav} from "./pages/multi-pdf-nav";
import {useEffect, useRef, useState} from "react";


function App() {

  const pdfFrame = useRef()
  const [pdfContent, setPdfContent] = useState(undefined)

  useEffect(() => {
    if (!pdfContent)
      return;



    const blob = new Blob([pdfContent], {type: 'application/pdf'});
    const blobUrl = URL.createObjectURL(blob);
    pdfFrame.current.src = blobUrl + "#toolbar=0"
  }, [pdfContent])


  return <>
    <NavBar />
    <Container className="mt-2">
      <Row>
        <Col>
          {pdfContent && <embed
              ref={pdfFrame}
              width="100%" height="1000px" />}
        </Col>
        <Col>
          <MultiPdfNav showPdf={setPdfContent} />
        </Col>
      </Row>

    </Container>
  </>

}

export default App;
