import {useEffect, useState} from "react";

export const MultiUpload = ({files, setFiles}) => {

    const [_files, _setFiles] = useState([]);

    function handleFileSelect(event) {
        _setFiles(fs => (
            Array.from(event.target.files)))
    }

    useEffect(() => {
        setFiles(_files)
    }, [_files, setFiles])

    return <div>
        <input type="file" multiple={false} onChange={handleFileSelect} />
        {/*{files.map((f, i) => <b key={i}>{f.name} </b>)}*/}
    </div>
}