import {Button, ButtonGroup, Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import {Fragment, useEffect, useState} from "react";
import {MultiUpload} from "../components/multi-upload";
import {pdfEdit, readFileAsync} from "../components/pdf-edit";

export const MultiPdfNav = ({showPdf}) => {


    const [currentFile, setCurrentFile] = useState(undefined)
    const [files, setFiles] = useState([])
    const [key, setKey] = useState('home');

    const [formS, setFormS] = useState("")
    const [formTF, setFormTF] = useState("")

    useEffect(() => {
        showPdf(currentFile)
    }, [currentFile, showPdf])

    useEffect(() => {

        setCurrentFile(files[0])
    }, [files])
    const closeAll = () => {
        setFiles([])
        showPdf(undefined)
    }

    const editPdf = () => {
        (async () => {
            const pdfContent = await readFileAsync(currentFile)
            // const blob = new Blob([pdfContent], {type: 'application/pdf'});
            const newPdf = await pdfEdit(pdfContent, {
                tipo: "PianoControlli",
                pbs_tf: formTF,
                pbs_s: formS
            })
            setCurrentFile(c => {
                c._content = newPdf
                return c
            })
            showPdf(newPdf)
            // console.log(blob)
        })().finally()
    }

    const scaricaPdf = () => {


        // Crea un link temporaneo per il download
        const link = document.createElement('a');
        const binaryData = [];
        binaryData.push(currentFile._content);
        link.href =window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}))

        link.download = `SC-${formTF}-${formS}.pdf`;

        // Simula il click del link per avviare il download
        link.click();
    }

    return <>
        <Row className="mt-3">
            <Col>

                <MultiUpload files={files} setFiles={setFiles}/>


                {/*<Button variant="secondary" onClick={closeAll}>*/}
                {/*    CHIUDI*/}
                {/*</Button>*/}


            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <>
                    {files.map((f, i) => <Fragment key={i}>
                        <Button
                            className={"tab-button " + (f === currentFile ? " selected" : " ")}
                            onClick={() => setCurrentFile(f)}
                        >{f.name}</Button>
                    </Fragment>)}
                </>
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control value="SC - Scheda Controlli" disabled={true}/>
                    {/*<Form.Text className="text-muted">*/}
                    {/*    Tecnologico Funzionale*/}
                    {/*</Form.Text>*/}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>PBS-TF</Form.Label>
                    <Form.Control value={formS} onChange={e => setFormS(e.target.value)} type="text"
                                  placeholder="Enter..."/>
                    {/*<Form.Text className="text-muted">*/}
                    {/*    Tecnologico Funzionale*/}
                    {/*</Form.Text>*/}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>PBS-S</Form.Label>
                    <Form.Control value={formTF} onChange={e => setFormTF(e.target.value)} type="text"
                                  placeholder="Enter..."/>
                    {/*<Form.Text className="text-muted">*/}
                    {/*    Spaziale*/}
                    {/*</Form.Text>*/}
                </Form.Group>


                <Button variant="success" onClick={editPdf}>
                    CONFERMA
                </Button>

            </Col>
        </Row>
        <Row className="mt-5">
            <Col>
                <Button variant="warning" onClick={scaricaPdf}>
                    SCARICA
                </Button>
            </Col>
        </Row>
    </>

}